@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"),
        url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat-Medium"),
        url("./fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Light";
    src: local("Montserrat-Light"),
        url("./fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "MoonTime-Regular";
    src: local("MoonTime-Regular"),
        url("./fonts/MoonTime-Regular.ttf") format("truetype");
}

body {
    margin: 0;
    background-color: #202020;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #242424;
}

.contactLine {
    font-family: Montserrat-Medium;
    color: #d8d3cb;
    font-size: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.contact {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    width: 100vw;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

.logo {
    display: flex;
    flex-direction: column;
    line-height: 40px;
}

.link {
    display: flex;
    text-decoration: none;
    color: #d8d3cb;
    align-items: center;
    justify-content: center;
}

.verticalLine {
    width: 3px;
    height: 200px;
    margin-left: 35px;
    margin-right: 35px;
    background-color: #d8d3cb;
}

.title {
    color: #eb0000;
    font-family: Copperplate;
    font-size: 5rem;
}

.subtitle {
    font-family: Copperplate;
    font-size: 2rem;
    color: #d8d3cb;
    text-align: center;
}

.icon {
    margin-right: 15px;
}

.hero {
    display: flex;
    flex-direction: "row";
    justify-content: space-evenly;
    align-items: center;
    width: "100vw";
    height: 400px;
    background-color: #d8d3cb;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.heroImage {
    height: 115%;
    width: auto;
    border-radius: 5px;
}

.verticalLineLeft {
    background-color: #d8d3cb;
    height: 3px;
    width: 90vw;
    margin-top: 35px;
    margin-bottom: 35px;
}

.verticalLineRight {
    background-color: #d8d3cb;
    height: 3px;
    align-self: flex-end;
    width: 90vw;
    margin-top: 35px;
    margin-bottom: 35px;
}

.about {
    background-color: #d8d3cb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.about-title {
    font-family: MoonTime-Regular;
    font-size: 4rem;
    margin: 25px 25px 5px 25px;
}

.about-body {
    font-family: Montserrat-Regular;
    margin: 5px 25px 25px 25px;
    line-height: 30px;
}

.about-content {
    display: flex;
    flex-direction: column;
}

.about-image {
    width: 50%;
    object-fit: cover;
}

.testimonials {
    position: relative;
    padding: 15px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.testimonial-images {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    z-index: 100;
    justify-content: flex-end;
}

.testimonials-social {
    font-family: MoonTime-Regular;
    font-size: 200%;
    color: #202020;
    justify-content: flex-end;
    text-decoration: none !important;
}

.testimonials-background {
    background-color: #d8d3cb;
    top: 0px;
    right: 0;
    width: 75vw;
    height: 100%;
    position: absolute;
    z-index: -5;
}

.testimonial-icon {
    height: 40px;
    margin-left: 15px;
}

.test-image {
    width: 25%;
}

.testimonial-link {
    margin-top: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.pricing {
    background-color: #d8d3cb;
    width: 90vw;
}

.pricing-title {
    font-family: MoonTime-Regular;
    font-size: 4rem;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.pricing-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.left {
    flex: 1;
    /* min-width: 500px; */
}

.right {
    flex: 1;
    /* min-width: 500px; */
}

.haircuts,
.shampoo,
.treatments,
.curls,
.chemical {
    box-sizing: border-box;
    padding: 40px;
    font-family: Montserrat-Regular;
}

.haircuts {
    box-sizing: border-box;
    border-right: 3px solid #beb4a4;
    border-top: 3px solid #beb4a4;
    border-bottom: 3px solid #beb4a4;
}
.shampoo {
    border-right: 3px solid #beb4a4;
    border-bottom: 3px solid #beb4a4;
}
.treatments {
    border-right: 3px solid #beb4a4;
    border-bottom: 3px solid #beb4a4;
}
.curls {
    border-right: 3px solid #beb4a4;
}
.chemical {
    border-top: 3px solid #beb4a4;
}

.pricing-header {
    font-family: Montserrat-Medium;
    font-size: 1.5rem;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.pricing-subheader {
    font-family: Montserrat-Medium;
    font-size: 1.4rem;
    margin-bottom: 5px;
}

.pricing-description {
    font-family: Montserrat-Light;
    font-size: 0.9rem;
    margin-bottom: 20px;
}

.pricing-line {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.service-name {
    font-family: Montserrat-Regular;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 75vw;
}

.contact-title {
    font-size: 5rem;
    color: #d8d3cb;
    font-family: MoonTime-Regular;
}

.contact-input {
    font-family: Montserrat-Regular;
    font-size: 20px;
    padding: 10px;
    background-color: #d8d3cb;
    border: none;
    margin-bottom: 10px;
}

.contact-input:focus {
    outline: none;
}

.contact-button {
    border: none;
    background-color: #d8d3cb;
    font-family: Montserrat-Medium;
    padding: 15px;
}

.footer {
    background-color: #d8d3cb;
    color: #202020;
    padding: 50px;
}

.footerContactLine {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    color: #202020;
    align-items: center;
    font-family: Montserrat-Medium;
}

.footerLink {
    display: flex;
    color: #202020;
    text-decoration: none;
    font-family: Montserrat-Medium;
    align-items: center;
}

.social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 803px) {
    .verticalLine {
        display: none;
    }
    .header {
        flex-direction: column;
        margin-bottom: 15px;
    }
    .contact {
        margin-top: 30px;
    }
}

@media (max-width: 840px) {
    .about {
        flex-direction: column;
    }
    .about-image {
        width: 100%;
    }
    .prices {
        flex-direction: column;
    }
    .haircuts {
        border-right: none;
    }
    .shampoo {
        border-right: none;
    }
    .treatments {
        border-right: none;
    }
    .curls {
        border-right: none;
    }
}

@media (max-width: 1085px) {
    .hero {
        display: none;
    }
}
